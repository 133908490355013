//local
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.23.44:1268/api/web/";
// const IMG_URL = "http://192.168.23.44:1268/api/uploads/images/";
// const LEAD_URL = "http://192.168.23.44:1268/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.23.44:1268";

//local new
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.10.40:5004/api/web/";
// const IMG_URL = "http://192.168.10.40:5004/api/uploads/images/";
// const LEAD_URL = "http://192.168.10.40:5004/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.10.40:5004";


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.135:1268/api/web/";
// const IMG_URL = "http://162.19.146.135:1268/api/uploads/images/";
// const LEAD_URL = "http://162.19.146.135:1268/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://162.19.146.135:1268/";

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.23.47:5009/api/web/";
// const IMG_URL = "http://192.168.23.47:5009/api/uploads/images/";
// const LEAD_URL = "http://192.168.23.47:5009/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.23.47:5009/";

//live

import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://api.mlmsuperstars.com/api/web/";
const IMG_URL = "https://api.mlmsuperstars.com/api/uploads/images/";
const LEAD_URL = "https://api.mlmsuperstars.com/api/web/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='903'
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
const BASE = "https://api.mlmsuperstars.com/";

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY,
   LEAD_URL
}


